import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import MyContext from '../components/Context'

// Export Template for use in CMS preview
export const GatePageTemplate = ({ sites_ar, sites_en }) => {
  return (
    <MyContext.Consumer>
      {cons => {
        if (!cons) {
          var cons = {
            strings: e => e,
            i18n: {
              language: 'ar'
            }
          }
        }
        return (
          <main className="Home" style={{}}>
            <section>
              {/* <div style={{ marginBottom: '20px' }}>
                <PageHeader
                  title={cons.i18n.language == 'en' ? title : title_ar}
                  subtitle={cons.i18n.language == 'en' ? subtitle : subtitle_ar}
                  backgroundImage={featuredImage}
                />
              </div> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                {(cons.i18n.language == 'en' ? sites_en : sites_ar).map(
                  (item, index) => (
                    <div
                      onClick={() => window.open(item.site_url, '_blank')}
                      className={`PostCard`}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '90%',
                        alignItems: 'center',
                        padding: '20px',
                        cursor: 'pointer',
                        marginRight: '10px',
                        marginBottom: '10px',
                        flexWrap: 'wrap'
                      }}
                    >
                      {item.image && (
                        <img
                          style={{ width: '100px', height: '100px' }}
                          src={item.image}
                          alt={item.alt}
                        />
                      )}
                      <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                        {item.site_name && (
                          <h3
                            className="PostCard--Title"
                            style={{ marginBottom: '10px' }}
                          >
                            {item.site_name}
                          </h3>
                        )}
                        {item.description && (
                          <div className="PostCard--Excerpt">
                            <p style={{ marginBottom: '10px' }}>
                              {item.description}
                            </p>
                            <p style={{ color: '#41942f' }}>{item.site_url}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </section>
          </main>
        )
      }}
    </MyContext.Consumer>
  )
}

// Export Default GatePage for front-end
const GatePage = ({ data: { page } }) => {
  return (
    <Layout meta={page.frontmatter.meta || false}>
      <GatePageTemplate {...page} {...page.frontmatter} body={page.html} />
    </Layout>
  )
}

export default GatePage

export const pageQuery = graphql`
  query GatePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        title_ar
        template
        subtitle
        subtitle_ar
        featuredImage
        sites_ar {
          site_name
          site_url
          description
          image
          alt
        }
        sites_en {
          site_name
          site_url
          description
          image
          alt
        }
      }
    }
  }
`
